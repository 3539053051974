import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { PostalAddress, SportsOrganization, WithContext } from 'schema-dts';
import {
	imgAppleTouchIconPng,
	imgFaviconSvg,
	imgFavicon_16x16Png,
	imgFavicon_32x32Png,
	imgFontsVtfWoff,
	imgFontsVtfWoff2,
	imgOtePng,
} from '~/src/images';
import config from '~/src/lib/config';
import styles from '~/src/lib/styles.json';
import CookieConsent from '~/src/overtime-lib/src/www/components/CookieConsent';
import DocumentContext from '~/src/overtime-lib/src/www/contexts/Document';
import { SocketProvider } from '~/src/overtime-lib/src/www/contexts/Socket';
import './Html.scss';

export default function Html({
	assets = {},
	children = null,
}: {
	assets: { [key: string]: any };
	children: React.ReactNode;
}) {
	// useGA('G-6SJJK8HBGX');
	// useFB('541772133741323');
	// useTikTok('CCKU3N3C77UDI0MAFDQG');
	// useSnap('458447e5-cf73-4faa-96ef-c7b7a49a20c5');

	const documentContext = useContext(DocumentContext);
	const oteDescription =
		"OTE offers the world's most talented basketball players a better pathway to becoming professional athletes. Powered by Overtime. Introducing a transformative sports league that offers a year-round development program combining world-class coaching, cutting-edge sports science and performance technology.";
	//&quot; isn't respected by all outlets so just use a curly quote
	const description = (documentContext.description ?? oteDescription).replace(/"/g, '”').replace(/\n/g, '\\n');

	const getAbsoluteUrl = (path: string) =>
		path.startsWith('http')
			? path.replace('http://localhost:8080/', 'https://overtimeelite.com/')
			: `https://overtimeelite.com${path}`;

	const absoluteLogoUrl = getAbsoluteUrl(imgOtePng);
	const imageUrls = Array.isArray(documentContext.image)
		? [...documentContext.image.filter((i) => !isEmpty(i)).map(getAbsoluteUrl), absoluteLogoUrl]
		: [!isEmpty(documentContext.image) ? getAbsoluteUrl(documentContext.image) : null, absoluteLogoUrl].filter(Boolean);

	const rawTitle = documentContext.title;
	const title = [rawTitle, 'OTE'].filter(Boolean).join(' | ');

	const color = documentContext.color ?? '#000000';

	const canonicalUrl = `https://overtimeelite.com${documentContext.canonicalPath ?? documentContext.location.pathname}${
		documentContext.location.search
	}`;

	const address: PostalAddress = {
		'@type': 'PostalAddress',
		streetAddress: '230 17th St NW',
		addressLocality: 'Atlanta',
		addressRegion: 'GA',
		postalCode: '30363',
	};

	const schemaData: WithContext<SportsOrganization> = {
		'@context': 'https://schema.org',
		'@type': 'SportsOrganization',
		name: 'OTE',
		alternateName: 'Overtime Elite',
		description: oteDescription,
		sport: 'Basketball',
		url: 'https://overtimeelite.com',
		logo: absoluteLogoUrl,
		address,
		parentOrganization: {
			'@type': 'Organization',
			name: 'Overtime',
			url: 'https://overtime.tv',
		},
		sameAs: [],
	};

	useEffect(() => {
		//Mobile browsers lie about vh so we're stuck with this nonsense
		window.onresize = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
		window.onresize = () => {
			//All this is necessary to handle scrollbar width problems
			document.documentElement.style.setProperty('--app-width', `${document.body.clientWidth}px`);
		};
		const observer = new ResizeObserver((_el) => window.onresize(null));
		observer.observe(document.body);

		window.onresize(null);
		return () => {
			window.onresize = null;
			observer.unobserve(document.body);
		};
	}, []);

	// TODO: Clean this up when not hotfixing for tracking errors
	useEffect(() => {
		if (location?.host === 'overtimeelite.com') {
			(function (h, o, u, n, d) {
				h = h[d] = h[d] || {
					q: [],
					onReady: function (c) {
						//@ts-ignore
						h.q.push(c);
					},
				};
				//@ts-ignore
				d = o.createElement(u);
				//@ts-ignore
				d.async = 1;
				//@ts-ignore
				d.src = n;
				//@ts-ignore
				n = o.getElementsByTagName(u)[0];
				//@ts-ignore
				n.parentNode.insertBefore(d, n);
			})(window, document, 'script', 'https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js', 'DD_RUM');
			//@ts-ignore
			window.DD_RUM.onReady(function () {
				//@ts-ignore
				window.DD_RUM.init({
					clientToken: 'pubf50ff247b6920635362e32d719e45d74',
					applicationId: 'e21933ec-e166-444e-b8e8-bc53396f83eb',
					site: 'datadoghq.com',
					service: 'overtimeelite.com',
					env: 'production',
					// Specify a version number to identify the deployed version of your application in Datadog
					// version: '1.0.0',
					sessionSampleRate: 10,
					sessionReplaySampleRate: 5,
					trackUserInteractions: true,
					trackResources: true,
					trackLongTasks: true,
					defaultPrivacyLevel: 'mask-user-input',
				});
			});
		}
	}, []);

	return (
		<html lang="en">
			<head>
				<title>{title}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta charSet="UTF-8" />
				<meta name="google-site-verification" content="_Npfn_L-weRdy7khF5vLyZVtGDv7o59P15vRfRpuFDw" />
				<meta name="google-site-verification" content="2kA3U3XEwCVR8zHoKOR9o12DITn1nJApUIJqQ1CKH-s" />
				<meta name="google-site-verification" content="AKheGLF0n028K_um_R3PAQHuImh2e_WEe3RXqzo5NaA" />
				<meta property="fb:app_id" content={config('OVERTIME_FB_APP')} />
				<meta property="og:site_name" content="OTE" />
				<meta property="og:title" content={rawTitle} />
				<meta property="og:description" content={description} />
				{imageUrls.map((i) => (
					<meta property="og:image" key={i} content={i} />
				))}
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:type" content="website" />
				<meta name="description" content={description} />
				<link rel="apple-touch-icon" sizes="180x180" href={imgAppleTouchIconPng} />
				<link rel="icon" type="image/svg+xml" href={imgFaviconSvg} />
				<link rel="icon" type="image/png" sizes="32x32" href={imgFavicon_32x32Png} />
				<link rel="icon" type="image/png" sizes="16x16" href={imgFavicon_16x16Png} />
				{/* <link rel="manifest" href="/site.webmanifest" /> */}
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color={styles.COLOR.BLACK} />
				<link rel="canonical" href={canonicalUrl} />
				<meta name="msapplication-TileColor" content={color} />
				<meta name="theme-color" content={color} />
				{/* Stop iOS from messing with text */}
				<meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(schemaData),
					}}
				/>
				<meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
				<style>{`
					
				`}</style>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" />
				<link rel="preload" href={imgFontsVtfWoff} as="font" type="font/woff" crossOrigin="anonymous" />
				<link rel="preload" href={imgFontsVtfWoff2} as="font" type="font/woff2" crossOrigin="anonymous" />
				<link rel="stylesheet" href="/public/fortawesome.css" />
				<link rel="stylesheet" href="/public/app.css" />
				{Object.values(assets)
					.filter((a) => a.endsWith('css'))
					.map((a) => (
						<link rel="stylesheet" key={a} href={a} />
					))}
			</head>
			<body>
				<div id="root">
					<SocketProvider>{children}</SocketProvider>
				</div>
				<CookieConsent googleMeasurementId="GTM-N8PJF2V" isDark={true} />
				<script
					dangerouslySetInnerHTML={{
						__html: `window.assetManifest = ${JSON.stringify(assets)};window.fetch = window.fetch.bind(window);`,
					}}
				/>
				<script dangerouslySetInnerHTML={{ __html: `window._documentContext = ${JSON.stringify(documentContext)};` }} />
				{Object.values(assets)
					.filter((a) => a.endsWith('js'))
					.map((a) => (
						<script defer key={a} src={a} />
					))}
				<script defer src="/public/app.js" />
				{/* <script type="text/javascript" src="https://vivenu.com/web/deliver/js/v1/embed.js" /> */}
			</body>
		</html>
	);
}

// export default Html;

import React from 'react';
import './ContentContainer.scss';

const ContentContainer = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
	<div className="content ContentContainer">
		<div {...props}>{props.children}</div>
	</div>
);

export default ContentContainer;

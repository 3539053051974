import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import './PageHeader.scss';

const PageHeader = ({ children, ctaText, ctaUrl }: { children: ReactNode; ctaText?: string; ctaUrl?: string }) => (
	<div className="PageHeader">
		<ContentContainer>
			<div className="HeaderContainer">
				<h1>{children}</h1>
				{!_.isEmpty(ctaText) && !_.isEmpty(ctaUrl) ? (
					<div className="Link">
						<a href={ctaUrl}>
							{ctaText} <FontAwesomeIcon icon={faArrowRight} />
						</a>
					</div>
				) : null}
			</div>
		</ContentContainer>
	</div>
);

export default PageHeader;

import React from 'react';
import Layout from '~/src/components/Layout';
import NotFound from '~/src/overtime-lib/src/www/components/NotFound';
import './NotFound.scss';

export default () => (
	<Layout>
		<NotFound />
	</Layout>
);

import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import wait from '../../lib/wait';
import { OvertimeEntity } from '../../models';
import { SocketContext } from '../contexts/Socket';

export type FetchResult<T> = { result: { read(): T }; promise: Promise<T>; fetched?: Date };
export function wrappedFetch<T>(url, options, key?: string): FetchResult<T> {
	const promise = wait(0)
		.then(() => fetch(url, { ...options, method: options?.method ?? 'GET' }))
		.then((r) => r.json())
		.then((o) => (key ? o[key] : o));

	return {
		promise,
		result: wrapPromise(promise),
		fetched: new Date(),
	};
}

export function wrapPromise<T>(promise: Promise<T>) {
	let status = 'pending';
	let result: T;
	let error;
	let suspender = promise.then(
		(r) => {
			status = 'success';
			result = r;
		},
		(e: Error) => {
			status = 'error';
			error = e;
		},
	);
	return {
		read() {
			if (status === 'pending') {
				throw suspender;
			} else if (status === 'error') {
				throw error;
			} else if (status === 'success') {
				return result;
			}
		},
	};
}

// Suspense relies on the promise being evaluated to be identical across renders
// However, when the promise resolves, Suspense rerenders all children from scratch
// This destroys any promises kept in state, whether its useState/useRef/useMemo
// To avoid this, we keep references to the promises outside of the React tree
// Take it up with the React team who designed Suspense
const fetches: { [k: string]: FetchResult<any> } = {};

type FetchParameters = { url: string; options?: RequestInit; key?: string };

function useFetch<T>({ url, options, key }: FetchParameters) {
	const fetchResult = fetches[url] ?? wrappedFetch<T>(url, options, key);
	if (_.isNil(fetches[url])) {
		fetches[url] = fetchResult;
		//Clear the fetch once per minute so the server doesn't render old data forever
		// setTimeout(() => (fetches[url] = null), 1000 * 60 * 5);
	}
	//If cached fetch is stale, fire a new fetch and replace the cached version if it completes
	// useFetch on the client is designed to be called once and cached after for a long time
	// with subsequent data updates being made by either WebSocket or explicit fetch
	// We allow for explicit data refetches for extremely long running windows
	const cacheExpiration = typeof window === 'undefined' ? 1000 * 5 : 1000 * 60 * 5;
	if (new Date().getTime() - fetchResult.fetched.getTime() > cacheExpiration) {
		const newFetch = wrappedFetch<T>(url, options, key);
		newFetch.promise.then(() => (fetches[url] = newFetch));
	}
	return fetchResult as FetchResult<T>;
}

export default useFetch;

export function useFetchAndSubscribe<T extends OvertimeEntity | OvertimeEntity[]>(options: FetchParameters) {
	// Storing the data as an array avoids a lot of redundant code making updates
	const dataRef = useRef<OvertimeEntity[]>();
	// We need the useState to trigger rerenders when data updates
	// but mutably changing state data is a bad time so we
	// store the data in a ref then pipe it back out to the state
	const [data, setData] = useState<OvertimeEntity[]>();

	const dataIndexRef = useRef<Record<string, number>>({});

	const { joinChannel } = useContext(SocketContext);

	//Do initial setup once after the fetch promise resolves
	const fetchResult = useFetch<T>(options);
	const fetchedData = fetchResult.result.read();
	const isArray = Array.isArray(fetchedData);

	useEffect(() => {
		if (!dataRef.current) {
			if (isArray) {
				dataRef.current = fetchedData;
			} else {
				dataRef.current = [fetchedData];
			}
			dataRef.current.forEach((d, i) => (dataIndexRef.current[d.id] = i));

			const singularKey = isArray ? options.key.slice(0, -1) : options.key;
			const pluralKey = isArray ? options.key : `${options.key}s`;

			const joinRelationChannel = (relation: string) => {
				const relationSingularKey = relation.slice(0, -1);

				joinChannel({
					channel: `public::${relation}`,
					// Typescript doesn't have a way to dynamically handle keys like this
					// but leaving in for clarity and future typing
					// @ts-ignore
					onMessage: (message: { [relationSingularKey]: T }) => {
						if (message[relationSingularKey]?.id) {
							const newData = [...(dataRef.current as OvertimeEntity[])];
							const index = dataIndexRef.current[message[relationSingularKey][`${singularKey}_id`]];
							if (index >= 0) {
								const relations = newData[index][relation] ?? [];
								const relationIndex = relations.findIndex((t) => t.id === message[relationSingularKey].id);
								if (relationIndex >= 0) {
									relations[relationIndex] = { ...relations[relationIndex], ...message[relationSingularKey] };
								} else {
									relations.push(message[relationSingularKey]);
								}
								newData[index] = { ...newData[index], [relation]: relations };
							}
							setData(newData);
						}
					},
				});
			};

			joinChannel({
				channel: `public::${pluralKey}${!isArray ? `::${fetchedData.id}` : ''}`,
				// Typescript doesn't have a way to dynamically handle keys like this
				// but leaving in for clarity and future typing
				// @ts-ignore
				onMessage: (message: { [singularKey]: T }) => {
					if (message[singularKey]?.id) {
						const newData = [...(dataRef.current as OvertimeEntity[])];
						const index = dataIndexRef.current[message[singularKey].id];
						if (index >= 0) {
							newData[index] = { ...newData[index], ...message[singularKey] };
						} else {
							newData.push(message[singularKey]);
							dataIndexRef.current[message[singularKey].id] = newData.length - 1;
						}
						dataRef.current = newData;
						setData(dataRef.current);
					}
				},
			});

			for (const key in dataRef.current[0] ?? {}) {
				if (Array.isArray(dataRef.current[0][key])) {
					joinRelationChannel(key);
				}
			}

			setData(dataRef.current);
		}
	}, []);

	const lastFocusFetchRef = useRef<number>(new Date().getTime());
	useEffect(() => {
		// There's room for optimization here as the socket
		// often survives a window blur/focus event
		// At some point should add a disconnect/reconnect event listener to the joinChannel
		// interface and trigger the full data fetch off that instead
		// For now just use a simple timer check to only refresh on focus once per minute
		const onFocus = async () => {
			if (new Date().getTime() - lastFocusFetchRef.current < 1000 * 60) {
				return;
			}
			lastFocusFetchRef.current = new Date().getTime();
			const { [options.key]: data } = await (await fetch(options.url)).json();
			if (isArray) {
				dataRef.current = data;
			} else {
				dataRef.current = [data];
			}
			setData(dataRef.current);
		};
		window.addEventListener('focus', onFocus);
		return () => {
			window.removeEventListener('focus', onFocus);
		};
	}, []);

	// Until setState has executed, return the fetch
	if (!data) {
		return fetchedData;
	}

	if (Array.isArray(fetchedData)) {
		return data as T;
	} else {
		return data[0] as T;
	}
}

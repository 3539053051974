import React from 'react';
import './NotFound.scss';

const NotFound = () => (
	<div className="NotFound">
		<h1>404</h1>
		<h2>Oops...</h2>
		<p>The page you're looking for isn't here.</p>
		<a href="/">Go Home</a>
	</div>
);

export default NotFound;

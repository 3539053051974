import React from 'react';
import Banner from '~/src/components/Banner';
import BoxScore from '~/src/components/BoxScore';
import Button from '~/src/components/Button';
import GameCard from '~/src/components/GameCard';
import Html from '~/src/components/Html';
import Standings from '~/src/components/Index/Standings';
import Layout from '~/src/components/Layout';
import PageHeader from '~/src/components/PageHeader';
import PlayoffsBracket from '~/src/components/PlayoffsBracket';
import GameList from '~/src/components/Schedule/GameList';
import SeasonLeaders from '~/src/components/SeasonLeaders';
import Table from '~/src/components/Table';
import Tabs from '~/src/components/Tabs';
import Header from '~/src/components/Team/Header';
import Player from '~/src/components/Team/Player';
import Picture from '~/src/overtime-lib/src/www/components/Picture';

/*
Add all shared elements here to fix Parcel being dumb
This component should never actually be used
*/

const Common = () => (
	<>
		{/* Parcel is trash and doesn't bundle CSS for components inside contexts */}
		<Html assets={{}} children={''} />
		<Layout>
			<></>
		</Layout>
		<PageHeader>Header</PageHeader>
		<Header team={undefined} />
		<Standings />
		<PlayoffsBracket ote_games={null} ote_team_standings={null} />
		<Banner site={null} />
		<Button href={undefined}>Hi</Button>
		<SeasonLeaders statistic="points" />
		<BoxScore rows={[]} />
		<Player player={undefined} team={undefined} />
		<GameCard ote_game={undefined} />
		<Tabs links={[]} root={''} color={''} />
		<Table headers={[]} currentHeader={''} rows={[]} />
		<Picture />
		<GameList games={[]} />
	</>
);

export default Common;

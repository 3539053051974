import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import GameCard from '~/src/components/GameCard';
import { OteGame } from '~/src/models/OteGame';
import { getEstDateTime } from '~/src/overtime-lib/src/www/lib';
import './GameList.scss';

const GameList = ({
	games,
	headerColor,
	headerTextColor,
}: {
	games: OteGame[];
	headerColor?: string;
	headerTextColor?: string;
}) => {
	// Force EST for first render to avoid hydration errors, then resort on client timezone
	const [shouldForceEst, setShouldForceEst] = useState(true);
	useEffect(() => {
		setShouldForceEst(false);
	}, []);
	const months = _.uniq(games.map((g) => getEstDateTime(g.starts_at, shouldForceEst).startOf('month').toISODate()));

	return (
		<div className="GameList">
			{months.map((month) => (
				<div className="DateHeaderContainer" key={month}>
					<div
						className="DateHeader"
						style={{ backgroundColor: headerColor, color: headerTextColor }}
						// Locale string will inevitably be different in different locales
						suppressHydrationWarning={true}
					>
						{DateTime.fromISO(month).toLocaleString({ month: 'long', year: 'numeric' })}
					</div>
					<div className="Games">
						{games
							.filter((g) => getEstDateTime(g.starts_at, shouldForceEst).startOf('month').toISODate() === month)
							.map((g, i) => (
								<GameCard key={g.id} ote_game={g} showFullTeamName={true} />
							))}
					</div>
				</div>
			))}
		</div>
	);
};

export default GameList;

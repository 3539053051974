import React from 'react';
import ContentContainer from '~/src/components/ContentContainer';
import { OteTeam } from '~/src/models/OteTeam';
import { colorFromInt } from '~/src/overtime-lib/src/www/lib';
import './Header.scss';

const Header = ({ team }: { team: OteTeam }) => (
	<h1 className="TeamHeader">
		<ContentContainer>
			<div className="HeaderContainer">
				{team?.is_current ? (
					<>
						<a className="inherit" href={`/teams/${team.slug}/roster`}>
							<img alt={team.name} src={`https://images.overtime.tv/${team.logo_path}`} />
							{team.name}
						</a>
						<style
							dangerouslySetInnerHTML={{
								__html: `
					header {
						--header-background-color: ${colorFromInt(team.primary_color ?? 0)};
						--header-foreground-color: ${colorFromInt(team.foreground_color ?? 0xffffff)} !important;
						
					}
					header svg path {
						fill: ${colorFromInt(team.foreground_color ?? 0xffffff)} !important;
					}
				`,
							}}
						></style>
					</>
				) : (
					'OTE'
				)}
			</div>
		</ContentContainer>
	</h1>
);
export default Header;

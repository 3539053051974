import React, { useCallback, useContext, useState } from 'react';
import DocumentContext from '../contexts/Document';
import './Picture.scss';

const _cdnify = (url: string, location: URL | Location) =>
	location.hostname === 'localhost' && (url.startsWith('/public') || url.split('/').length < 3) //Local only path
		? url
		: url.startsWith('/public') //Local resources
		  ? `https://images.overtime.tv/${location.host}${url}`
		  : `https://images.overtime.tv${url}`; //S3 resources

const getSource = (
	src: string,
	options: { quality?: number; noindex?: boolean; width?: number; format?: string } = {
		noindex: false,
		format: 'webp',
	},
) => {
	try {
		const url = new URL(src, 'http://localhost'); //Use a base so we can operate on path-only sources

		for (const key in options) {
			if (!options[key]) {
				continue;
			}
			url.searchParams.delete(key);
			url.searchParams.append(key, options[key]);
		}
		return `${url.pathname}${url.search}`;
	} catch (error) {
		console.error(error);
		return '';
	}
};

const Picture = ({
	src,
	width,
	mobileWidth,
	quality = 70,
	...rest
}: { width?: number; mobileWidth?: number; quality?: number } & React.ImgHTMLAttributes<HTMLImageElement>) => {
	const [isFullImageLoaded, setIsFullImageLoaded] = useState(false);
	const desktopPlaceholderSrc = getSource(src, { width, quality: 1, noindex: true });
	const placeholderSrc = getSource(desktopPlaceholderSrc, { width: mobileWidth });
	const mobileSrc = getSource(src, { width: mobileWidth });
	const fullSrc = getSource(src, { width, quality });

	const fullImageRef = (ref: HTMLImageElement) => {
		if (!ref) {
			return;
		}
		ref.onload = () => {
			setIsFullImageLoaded(true);
		};
		if (ref.complete) {
			setIsFullImageLoaded(true);
		}
	};

	const { location } = useContext(DocumentContext);
	const cdnify = useCallback((url: string) => _cdnify(url, location), [location]);

	return (
		<div className="Picture">
			<picture className="PicturePlaceholder">
				<source srcSet={cdnify(desktopPlaceholderSrc)} media={`(min-width: 1024px)`} />
				<img src={cdnify(placeholderSrc)} {...rest} />
			</picture>
			{mobileWidth ? (
				<picture className="PictureFull">
					<source srcSet={cdnify(fullSrc)} media={`(min-width: 1024px)`} />
					<img ref={fullImageRef} src={cdnify(mobileSrc)} {...rest} />
				</picture>
			) : (
				<img ref={fullImageRef} className="PictureFull" src={cdnify(fullSrc)} {...rest} />
			)}
		</div>
	);
};

export default Picture;

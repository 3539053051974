import { isNil } from 'lodash';
import React from 'react';
import './Table.scss';

interface TableHeader {
	value?: string;
	label: string;
	mobileLabel?: string;
}
const Table = ({
	title,
	headers,
	currentHeader,
	onHeaderSelect,
	rows,
	useHash = true,
}: {
	title?: string;
	headers: TableHeader[];
	currentHeader: string;
	onHeaderSelect?: (string) => void;
	rows: JSX.Element[];
	useHash?: boolean;
}) => (
	<div className="Table">
		<table>
			<thead>
				<tr>
					<th
						className="Title"
						role={!isNil(headers[0]?.value) ? 'button' : undefined}
						onClick={() => {
							useHash ? window.history.replaceState({}, document.title, location.pathname) : null;
							onHeaderSelect && onHeaderSelect(null);
						}}
					>
						{title}
					</th>
					{headers.map((h) => (
						<th
							key={h.value ?? h.label}
							role={!isNil(h?.value) ? 'button' : undefined}
							onClick={() => {
								if (isNil(h.value)) {
									return;
								}
								useHash ? window.history.replaceState({}, document.title, location.pathname + '#' + h.value) : null;
								onHeaderSelect(h.value);
							}}
							className={currentHeader === h.value ? 'current' : null}
						>
							<span className="mobile-only">{h.mobileLabel ?? h.label}</span>
							<span className="desktop-only">{h.label}</span>
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	</div>
);

export default Table;

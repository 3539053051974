import { OvertimeModel } from '.';
import { OteSeason } from './OteSeason';
import { OteSeriesOteTeam } from './OteSeriesOteTeam';
import { OteStage } from './OteStage';

export interface OteSeries extends OvertimeModel {
	abbreviation?: string;
	added: string;
	auto_calculated: boolean;
	best_of?: number;
	end_date?: string;
	max_fixtures: number;
	name: string;
	pool_code?: string;
	series_number?: number;
	series_order?: string;
	series_type: string;
	start_date?: string;
	status: string;
	synergy_series_code: string;
	synergy_stage_code: string;
	winner?: string;

	ote_season_id: string;
	ote_stage_id: string;

	ote_series_ote_teams?: OteSeriesOteTeam[];
	ote_season: OteSeason;
	ote_stage: OteStage;
}

export const isSeriesWinner = (ote_series_ote_team: OteSeriesOteTeam, ote_series: OteSeries) =>
	ote_series_ote_team?.series_wins >= ote_series?.max_fixtures / 2;
export const isSeriesLoser = (ote_series_ote_team: OteSeriesOteTeam, ote_series: OteSeries) =>
	ote_series_ote_team?.series_losses >= ote_series?.max_fixtures / 2;
export const isComplete = (ote_series_ote_team: OteSeriesOteTeam, ote_series: OteSeries) =>
	isSeriesWinner(ote_series_ote_team, ote_series) || isSeriesLoser(ote_series_ote_team, ote_series);

import React, { ReactNode, useContext, useEffect, useState } from 'react';
import type { Color } from '../lib';

type ContextType = {
	location: URL | Location;
	title: string;
	setTitle: React.Dispatch<React.SetStateAction<string>>;
	image: string | string[];
	setImage: React.Dispatch<React.SetStateAction<string | string[]>>;
	description: string;
	setDescription: React.Dispatch<React.SetStateAction<string>>;
	color: Color;
	setColor: React.Dispatch<React.SetStateAction<Color>>;
	canonicalPath: string;
	setCanonicalPath: React.Dispatch<React.SetStateAction<string>>;
};
const windowContext = typeof window !== 'undefined' ? (window as any)._documentContext : null;
const defaultContext: ContextType = {
	...{
		location: null,
		title: null,
		setTitle: () => {},
		image: null,
		setImage: () => {},
		description: null,
		setDescription: () => {},
		color: null,
		setColor: () => {},
		canonicalPath: null,
		setCanonicalPath: () => {},
	},
	...windowContext,
};
const DocumentContext = React.createContext(defaultContext);
export const { Provider, Consumer } = DocumentContext;

const cache: Record<string, ContextType> = {};

//Moving this into a hook DRYs up the need to call the set both at the context level
//and at the global level for future calls to be already saturated
//Components should use this method rather than directly accessing the context's setters
export const useSetDocumentState = ({
	title,
	image,
	color,
	description,
	canonicalPath,
}: {
	title?: string;
	image?: string | string[];
	color?: Color;
	description?: string;
	canonicalPath?: string;
}) => {
	const { location, setTitle, setImage, setColor, setDescription, setCanonicalPath } = useContext(DocumentContext);
	const cacheKey = location.pathname + location.search;

	useEffect(() => {
		if (title) {
			setTitle(title);
		}
	}, [title]);
	cache[cacheKey] = { ...(cache[cacheKey] ?? defaultContext), title };

	useEffect(
		() => {
			if (image) {
				setImage(image);
			}
		},
		Array.isArray(image) ? image : [image],
	);
	cache[cacheKey] = { ...(cache[cacheKey] ?? defaultContext), image };

	useEffect(() => {
		if (color) {
			setColor(color);
		}
	}, [color]);
	cache[cacheKey] = { ...(cache[cacheKey] ?? defaultContext), color };

	useEffect(() => {
		if (description) {
			setDescription(description);
		}
	}, [description]);
	cache[cacheKey] = { ...(cache[cacheKey] ?? defaultContext), description };

	useEffect(() => {
		if (canonicalPath) {
			setCanonicalPath(canonicalPath);
		}
	}, [canonicalPath]);
	cache[cacheKey] = { ...(cache[cacheKey] ?? defaultContext), canonicalPath };
};

export const DocumentProvider = ({ location, children }: { location: URL | Location; children: ReactNode }) => {
	const cacheKey = location.pathname + location.search;

	const [title, setTitle] = useState<string>(cache[cacheKey]?.title ?? defaultContext.title);
	const [image, setImage] = useState<string | string[]>(cache[cacheKey]?.image ?? defaultContext.image);
	const [description, setDescription] = useState<string>(cache[cacheKey]?.description ?? defaultContext.description);
	const [color, setColor] = useState<Color>(cache[cacheKey]?.color ?? defaultContext.color);
	const [canonicalPath, setCanonicalPath] = useState<string>(
		cache[cacheKey]?.canonicalPath ?? defaultContext.canonicalPath,
	);

	// useEffect(() => {
	// 	cache[cacheKey] = { ...(cache[cacheKey] ?? defaultContext), title };
	// 	// console.log('CACHE', cache[cacheKey]);
	// }, [title, cacheKey]);

	const value = {
		location,
		title,
		setTitle,
		image,
		setImage,
		description,
		setDescription,
		color,
		setColor,
		canonicalPath,
		setCanonicalPath,
	};

	return <Provider value={value}>{children}</Provider>;
};
export default DocumentContext;

import _ from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from '~/src/lib/styles.json';
import { capitalize } from '~/src/overtime-lib/src/www/lib';
import './Tabs.scss';

const Link = ({
	color,
	currentSection,
	section,
	label,
	index,
	root,
	useHash,
	onClick,
}: {
	color: string;
	section: string;
	currentSection: string;
	label: string;
	index: number;
	root: string;
	useHash: boolean;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}) => {
	const isCurrent = (index === 0 && _.isEmpty(currentSection)) || currentSection === section;
	return (
		<a
			className={isCurrent ? 'current' : ''}
			style={{ borderBottomColor: isCurrent ? color : undefined }}
			href={`${root}${section ? `${useHash ? '#' : '/'}${section}` : ''}`}
			onClick={onClick}
		>
			{capitalize(label)}
		</a>
	);
};

const Tabs = ({
	links,
	root = '',
	color = styles.COLOR.BRAND_PRIMARY,
	useHash = false,
	useCallback,
	section,
	useHistoryState = false,
	scrollToCenter = false,
}: {
	links: (string | string[])[];
	root?: string;
	color?: string;
	useHash?: boolean;
	useCallback?: (v: string) => void;
	section?: string;
	useHistoryState?: boolean;
	scrollToCenter?: boolean;
}) => {
	const { pathname } = useLocation();
	const initialSection = section ? section : useHash ? useLocation().hash.slice(1) : useParams().section;
	const [currentSection, setCurrentSection] = useState<string>(useHash ? null : initialSection);
	useEffect(() => {
		setCurrentSection(initialSection);
	}, [initialSection]);
	const ref = useRef<HTMLDivElement>();

	//Avoid React SSR throwing a warning
	if (typeof window !== 'undefined') {
		useLayoutEffect(() => {
			const nav = ref.current;
			if (!nav) {
				return;
			}
			const first = nav.firstChild as HTMLAnchorElement;
			const current = nav.getElementsByClassName('current')[0] as HTMLAnchorElement;
			if (!first || !current) {
				return;
			}
			if (scrollToCenter) {
				current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			} else if (
				current.offsetLeft - nav.scrollLeft < 0 ||
				current.offsetLeft - nav.scrollLeft + current.offsetWidth > nav.getBoundingClientRect().width
			) {
				nav.scrollTo(current.offsetLeft - first.offsetLeft, 0);
			}
		}, [links]);
	}

	if (useHistoryState) {
		useEffect(() => {
			window.onpopstate = function () {
				const tab = pathname.split('/').pop();
				if (links.map((l) => l[0]).includes(tab)) {
					useCallback(tab);
				} else {
					useCallback(links[0][0]);
				}
			};
		}, [pathname, links, useCallback]);
	}

	return (
		<nav className="Tabs" ref={ref}>
			{links
				.map((s) => (Array.isArray(s) ? s : [s, s]))
				.map((s, i) => (
					<Link
						key={s[0]}
						section={s[0]}
						label={s[1]}
						index={i}
						currentSection={currentSection}
						color={color}
						root={root}
						useHash={useHash}
						onClick={
							useCallback
								? (e) => {
										e.preventDefault();
										if (useHistoryState) {
											window.history.pushState(
												{},
												document.title,
												`${root}${s[0] ? `${useHash ? '#' : '/'}${s[0]}` : ''}`,
											);
										}
										useCallback(s[0]);
									}
								: undefined
						}
					/>
				))}
		</nav>
	);
};
export default Tabs;

import React from 'react';

export default (props) => (
	<svg width="261" height="57" viewBox="0 0 261 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M204.9 12.6H248L261 0H181.7L184.9 8.5L145 56.1H212.2L222.7 43.5H179L186.7 34.4H230.4L241 21.8H197.3L204.9 12.6Z"
			fill={props.color ?? 'white'}
		/>
		<path
			d="M174.9 12.6L178.3 8.5L175.1 0H105.1L107.9 6.89999L110.2 12.6H127.3L90.8 56.1H114.3L150.8 12.6H174.9Z"
			fill={props.color ?? 'white'}
		/>
		<path
			d="M48.7 43.5H22.7L48.6 12.6H74.6L48.7 43.5ZM98.6 0H37.1L0 43.7L4.8 56.1H60.8L101.4 7.5L98.6 0Z"
			fill={props.color ?? 'white'}
		/>
	</svg>
);

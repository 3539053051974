import React, { createContext, useEffect, useRef } from 'react';

export interface JoinChannelOptions {
	channel: string;
	onMessage: (message: object) => void;
}
interface SocketContextType {
	joinChannel: (JoinChannelOptions) => void;
}

const defaultContext: SocketContextType = {
	joinChannel: () => {},
};
export const SocketContext = createContext(defaultContext);
export const { Provider, Consumer: SocketConsumer } = SocketContext;

export const SocketProvider = ({ children }) => {
	if (typeof WebSocket === 'undefined') {
		if (typeof window !== 'undefined') {
			console.log('No WebSocket available');
		}
		return <Provider value={defaultContext}>{children}</Provider>;
	}

	const socketRef = useRef<WebSocket>();
	const channelSubscribersRef = useRef<{}>({});
	const lastPingRef = useRef<Date>(new Date());
	const lastPongRef = useRef<Date>(new Date());

	const connectSocket = () => {
		console.log('Connecting');
		const socket = new WebSocket(`wss://notifications.itsovertime.com`);
		socket.onerror = (error) => {
			console.error('WebSocket error', error);
		};
		socket.onclose = () => {
			if (socketRef.current !== socket) {
				return;
			}
		};
		socket.onopen = () => {
			console.log('Socket connected');
			lastPongRef.current = new Date();
			for (const channel in channelSubscribersRef.current) {
				socketRef.current?.send(JSON.stringify({ data: { action: 'join_channel', channel, is_user_channel: false } }));
				console.log('Joined', channel);
			}
		};
		socket.onmessage = (e) => {
			if (e.data === 'pong') {
				lastPongRef.current = new Date();
				return;
			}
			const data = JSON.parse(e.data);
			if (data.channel) {
				channelSubscribersRef.current[data.channel]?.forEach((fn) => fn(JSON.parse(data.data)));
			}
		};
		socketRef.current = socket;
	};
	useEffect(() => {
		const id = setInterval(() => {
			if (
				lastPingRef.current.getTime() - lastPongRef.current.getTime() > 3 * 1000 &&
				socketRef.current?.readyState !== WebSocket?.CONNECTING
			) {
				socketRef.current?.close();
				socketRef.current = null;
				connectSocket();
				return;
			}

			if (socketRef.current?.readyState === WebSocket?.OPEN) {
				lastPingRef.current = new Date();
				socketRef.current?.send('ping');
			} else if (socketRef.current?.readyState !== WebSocket?.CONNECTING) {
				connectSocket();
			}
		}, 1000);

		return () => {
			clearInterval(id);
		};
	}, []);
	useEffect(() => {
		connectSocket();
		return () => {
			socketRef.current?.close();
			socketRef.current = null;
		};
	}, []);

	const joinChannel = ({ channel, onMessage }: JoinChannelOptions) => {
		if (socketRef.current?.readyState === WebSocket?.OPEN) {
			socketRef.current?.send(JSON.stringify({ data: { action: 'join_channel', channel, is_user_channel: false } }));
			console.log('Joined', channel);
		} else {
			setTimeout(() => {
				joinChannel({ channel, onMessage });
			}, 1000);
			return;
		}

		channelSubscribersRef.current = {
			...channelSubscribersRef.current,
			[channel]: [...(channelSubscribersRef.current[channel] || []), onMessage],
		};
	};

	return <Provider value={{ joinChannel }}>{children}</Provider>;
};

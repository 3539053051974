import React, { createElement, lazy } from 'react';
import { Route } from 'react-router-dom';
import Suspended from '~/src/components/Suspended';
// import Index from '~/src/components/pages/Index';
// import About from '~/src/components/pages/About';
import NotFound from '~/src/components/pages/NotFound';
// import Team from '~/src/components/pages/Team';

//This is a stupid hack to get Parcel to put shared element styles in app.css where they belong
import Common from '~/src/components/Common';
//@ts-ignore
const _ = Common;

const Index = lazy(() => import('~/src/components/pages/Index'));
const About = lazy(() => import('~/src/components/pages/About'));
const Covid = lazy(() => import('~/src/components/pages/Covid'));
const Events = lazy(() => import('~/src/components/pages/Events'));
const Game = lazy(() => import('~/src/components/pages/Game'));
const OvertimeElitePrep = lazy(() => import('~/src/components/pages/OvertimeElitePrep'));
const OvertimeElitePrepLegal = lazy(() => import('~/src/components/pages/OvertimeElitePrepPlayerCodeOfConduct'));
const Player = lazy(() => import('~/src/components/pages/Player'));
const Players = lazy(() => import('~/src/components/pages/Players'));
const PlayerSeason = lazy(() => import('~/src/components/pages/PlayerSeason'));
const Post = lazy(() => import('~/src/components/pages/Post'));
const Posts = lazy(() => import('~/src/components/pages/Posts'));
const Schedule = lazy(() => import('~/src/components/pages/Schedule'));
const Scores = lazy(() => import('~/src/components/pages/Scores'));
const Standings = lazy(() => import('~/src/components/pages/Standings'));
const Season = lazy(() => import('~/src/components/pages/Season'));
const Statistics = lazy(() => import('~/src/components/pages/Statistics'));
const Team = lazy(() => import('~/src/components/pages/Team'));
const Teams = lazy(() => import('~/src/components/pages/Teams'));
const Tickets = lazy(() => import('~/src/components/pages/Tickets'));
const TicketTerms = lazy(() => import('~/src/components/pages/TicketTerms'));

const LazyElement = ({ element }: { element: React.LazyExoticComponent<any> }) => (
	<Suspended>{createElement(element)}</Suspended>
);

//Set key equal to element name so styles get included in SSR
export const Routes = (
	<Route>
		<Route path="/" element={<LazyElement key="Index" element={Index} />} />
		<Route path="/about" element={<LazyElement key="About" element={About} />}>
			<Route path=":section" element={<LazyElement key="About" element={About} />} />
		</Route>
		<Route path="/schedule" element={<LazyElement key={'Schedule'} element={Schedule} />} />
		<Route path="/scores" element={<LazyElement key={'Scores'} element={Scores} />} />
		<Route path="/standings" element={<LazyElement key={'Standings'} element={Standings} />} />
		{/* <Route path="/events" element={<LazyElement key="Events" element={Events} />} /> */}
		<Route path="/teams" element={<LazyElement key="Teams" element={Teams} />} />
		<Route path="/teams/:id" element={<LazyElement key="Team" element={Team} />}>
			<Route path=":section" element={<LazyElement key="Team" element={Team} />} />
		</Route>
		<Route path="/statistics" element={<LazyElement key="Statistics" element={Statistics} />} />
		<Route path="/statistics/:season_id" element={<LazyElement key="Statistics" element={Statistics} />} />
		<Route path="/players" element={<LazyElement key="Players" element={Players} />} />
		<Route path="/players/:id" element={<LazyElement key="Player" element={Player} />} />
		<Route path="/players/:id/:section" element={<LazyElement key="Player" element={Player} />} />
		<Route path="/players/:id/seasons/:season_id" element={<LazyElement key="PlayerSeason" element={PlayerSeason} />} />
		<Route path="/seasons/:id" element={<LazyElement key={'Season'} element={Season} />} />
		<Route path="/games/:id" element={<LazyElement key={'Game'} element={Game} />} />
		<Route path="/games/:id/:section" element={<LazyElement key={'Game'} element={Game} />} />
		{/* <Route path="/posts" element={<LazyElement key="Posts" element={Posts} />} />
		<Route path="/posts/:id" element={<LazyElement key="Post" element={Post} />} /> */}
		<Route path="/prep" element={<LazyElement key="OvertimeElitePrep" element={OvertimeElitePrep} />} />
		<Route
			path="/prep/player-code-of-conduct"
			element={<LazyElement key="OvertimeElitePrepLegal" element={OvertimeElitePrepLegal} />}
		/>
		<Route path="/tickets" element={<LazyElement key="Tickets" element={Tickets} />} />

		<Route path="/covid" element={<LazyElement key={'Covid'} element={Covid} />} />
		<Route path="/ticket_terms" element={<LazyElement key="TicketTerms" element={TicketTerms} />} />
		<Route path="/not_found" element={<NotFound key="NotFound" />} />
		<Route path="*" element={<NotFound key="NotFound" />} />
	</Route>
);

// Production
const baseRestApiUrl = 'https://api.itsovertime.com';
const basePlatformsApiUrl = 'https://api.itsovertime.com';

// Local development
// const basePlatformsApiUrl = 'http://overtime.home:8082';
// const baseRestApiUrl = 'http://overtime.home:8081';

export enum Urls {
	ApiPlatformsBeehiivPostsUrl = `${basePlatformsApiUrl}/api/platforms/v1/beehiiv/posts`,
	ApiPlatformsVivenuEventsUrl = `${basePlatformsApiUrl}/api/platforms/v1/vivenu/events`,
	ApiPlatformsVivenuEventInfo = `${basePlatformsApiUrl}/api/platforms/v1/vivenu/events/info`,
	ApiPlatformsYouTubeChannelOteUrl = `${basePlatformsApiUrl}/api/platforms/youtube/ote`,
	ApiPlatformsYouTubeChannelOteLiveUrl = `${basePlatformsApiUrl}/api/platforms/youtube/ote_live`,
	ApiPlatformsYouTubeChannelOteShortsUrl = `${basePlatformsApiUrl}/api/platforms/youtube/ote_shorts`,
	ApiPlatformsYouTubeVideosUrl = `${basePlatformsApiUrl}/api/platforms/youtube/videos`,

	ApiRestBannersUrl = `${baseRestApiUrl}/api/banners/v1/public`,
	ApiRestShopifyCollectionsUrl = `${baseRestApiUrl}/api/shopify_collections/v1/public`,
	ApiRestOteEventsUrl = `${baseRestApiUrl}/api/ote_events/v1/public`,
	ApiRestOteGameBaseUrl = `${baseRestApiUrl}/api/ote_games/v1/public`,
	ApiRestOteGamesUrl = `${baseRestApiUrl}/api/ote_games/v2/public`,
	ApiRestOtePlayersUrl = `${baseRestApiUrl}/api/ote_players/v1/public`,
	ApiRestOteSeasonsUrl = `${baseRestApiUrl}/api/ote_seasons/v1/public`,
	ApiRestOteTeamsUrl = `${baseRestApiUrl}/api/ote_teams/v1/public`,
	ApiRestOteTeamsCurrentUrl = `${baseRestApiUrl}/api/ote_teams/v1/current`,
	ApiRestOteTeamStandingsBaseUrl = `${baseRestApiUrl}/api/ote_team_standings/v1`,
	ApiRestOteTeamStandingsCurrentUrl = `${baseRestApiUrl}/api/ote_team_standings/v1/current`,
	ApiRestOtePrepVerifyEmail = `${baseRestApiUrl}/api/ote_prep/v1/public/email`,

	AmazonPrimeVideoBaseUrl = 'https://www.amazon.com/gp/video/detail',

	BeehiivOteUrl = 'https://ote.beehiiv.com',

	GoogleFormsGroupTickets = 'https://docs.google.com/forms/d/1lceD_8XU0oTbcSTpsg_-XIbpYUYHCU2DyE3auKecaZQ',

	ShopifyCollectionOteUrl = 'https://shop.overtime.tv/collections/ote',

	VivenuTicketsEventBaseUrl = 'https://tickets.itsovertime.com/event',
	VivenuTicketsOtePackagesUrl = 'https://tickets.itsovertime.com/section/packages-3n62',
	VivenuTicketsUrl = 'https://tickets.itsovertime.com',

	YouTubeVideoBaseUrl = 'https://www.youtube.com/watch',
	YouTubeOteChannel = 'https://www.youtube.com/@OTE',
	YouTubeOteShorts = 'https://www.youtube.com/@OTE/shorts',

	WebToonsOvertimeElite = 'https://www.webtoons.com/en/sports/overtime-elite/list?title_no=6724',
	WebToonsOvertimeEliteEpisode1 = 'https://www.webtoons.com/en/sports/overtime-elite/episode-1/viewer?title_no=6724&episode_no=1',
}

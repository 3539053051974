import React from 'react';
import Header from '~/src/components/Header';
import ContentContainer from './ContentContainer';
import Footer from './Footer';
import './Layout.scss';

const Layout = ({ children }) => (
	<ContentContainer className="Layout">
		<Header />
		<div>{children}</div>
		<Footer />
	</ContentContainer>
);
export default Layout;

import React from 'react';
import Button from '~/src/components/Button';
import ContentContainer from '~/src/components/ContentContainer';
import { Urls } from '~/src/lib/enums/urls';
import { Banner } from '~/src/models/Banner';
import { OteGame } from '~/src/models/OteGame';
import useFetch, { useFetchAndSubscribe } from '~/src/overtime-lib/src/www/hooks/useFetch';
import './Banner.scss';
import PhotoVideo from './PhotoVideo';

const Banner = ({ site }: { site: string }) => {
	// Fetch default banner data
	const banners = useFetch<Banner[]>({
		url: `${Urls.ApiRestBannersUrl}?site=${site}`,
		key: 'banners',
	});
	const banner = banners.result.read()?.at(0);

	//  Fetch all live games data
	const live_ote_games = useFetchAndSubscribe<OteGame[]>({
		url: `${Urls.ApiRestOteGamesUrl}?status=live`,
		key: 'ote_games',
	});

	// Filter for live games with stream link(s)
	const liveOteGamesWithStream = live_ote_games.filter((g) => g.youtube_id || g.prime_video_id);

	// Set preferred live game (YouTube first, then Amazon Prime Video)
	const preferredLiveGame =
		liveOteGamesWithStream.find((g) => g.youtube_id) || liveOteGamesWithStream.find((g) => g.prime_video_id);

	// Generate liveGameCta URL and team names if preferred live game exists
	let awayTeamName = '';
	let homeTeamName = '';
	let streamingPlatform = '';
	let liveGameCta = '';

	if (preferredLiveGame) {
		streamingPlatform = preferredLiveGame?.youtube_id ? 'YouTube' : 'Prime';
		liveGameCta = preferredLiveGame?.youtube_id
			? `${Urls.YouTubeVideoBaseUrl}/${preferredLiveGame?.youtube_id}`
			: `${Urls.AmazonPrimeVideoBaseUrl}/${preferredLiveGame?.prime_video_id}`;

		const [_team1, _team2] = preferredLiveGame.ote_games_ote_teams;
		if (_team1.is_home_team) {
			awayTeamName = _team2.ote_team.name;
			homeTeamName = _team1.ote_team.name;
		} else {
			awayTeamName = _team1.ote_team.name;
			homeTeamName = _team2.ote_team.name;
		}
	}

	if (!banner) {
		return <></>;
	}

	return (
		<div className="Banner">
			<ContentContainer>
				<div className="Container">
					<a className="ImageContainer" title={banner.title} href={banner.url}>
						<PhotoVideo
							alt={banner.title}
							src={banner.image_path}
							width={850}
							mobileWidth={800}
							height={850 / (16 / 9)}
						/>
					</a>
					<div className="BannerContent">
						{preferredLiveGame ? (
							<>
								<h2>Game Time</h2>
								<p>
									Check out {awayTeamName} vs. {homeTeamName} streaming live on {streamingPlatform}!
								</p>
								<div className="ButtonsContainer">
									<Button href={liveGameCta}>Watch Now</Button>
								</div>
							</>
						) : (
							<>
								<h2>{banner.title}</h2>
								<p>{banner.text}</p>
								{banner.url && banner.url_text && (
									<div className="ButtonsContainer">
										<Button href={banner.url}>{banner.url_text}</Button>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</ContentContainer>
		</div>
	);
};

export default Banner;

import React, { ReactEventHandler, ReactNode } from 'react';
import './Button.scss';
const Button = ({
	children,
	href,
	...rest
}: {
	children: ReactNode;
	href?: string;
	onClick?: ReactEventHandler;
	className?: string;
	style?: object;
}) =>
	href ? (
		<a className="Button" href={href} {...rest}>
			<div>{children}</div>
		</a>
	) : (
		<button className="Button" {...rest}>
			<div>{children}</div>
		</button>
	);

export default Button;

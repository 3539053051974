import { OvertimeModel } from '.';
import { OteSeason } from './OteSeason';

export interface OteStage extends OvertimeModel {
	abbreviation: string;
	name: string;
	stage_order: number;
	synergy_stage_code: string;

	ote_season_id: string;

	ote_season: OteSeason;
}

export const getStageNameAbbreviated = (ote_stage: OteStage) =>
	ote_stage?.name.replace('Round', 'Rd').replace('Semi-Finals', 'Semis');

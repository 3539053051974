import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { imgOteLogoWhiteSvg } from '~/src/images';
import { positions } from '~/src/lib';
import styles from '~/src/lib/styles.json';
import { OtePlayer } from '~/src/models/OtePlayer';
import { OteTeam } from '~/src/models/OteTeam';
import { colorFromInt } from '~/src/overtime-lib/src/www/lib';
import './Player.scss';

const Player = ({
	team,
	player,
	shouldUseTeamColor = true,
}: {
	team?: OteTeam;
	player: OtePlayer;
	shouldUseTeamColor?: boolean;
}) => {
	const backgroundColor = shouldUseTeamColor
		? team
			? colorFromInt(team.secondary_color)
			: styles.COLOR.BRAND_PRIMARY
		: styles.COLOR.BLACK;
	const foregroundColor = shouldUseTeamColor ? colorFromInt(team?.secondary_foreground_color ?? 0xffffff) : 'white';

	return (
		<a className="TeamPlayer" href={`/players/${player.id}`} style={{ backgroundColor }}>
			<svg height="1" width="1" viewBox="0 0 1 1" className="Triangle">
				<polygon points="0,0 0,1 1,0" fill={backgroundColor} />
			</svg>
			<div
				style={{
					backgroundImage: `linear-gradient(180deg, ${colorFromInt(team?.primary_color ?? 0xaaaaaa)} 0%, ${colorFromInt(
						team?.primary_color ?? 0xaaaaaa,
					)} 20% , white)`,
				}}
				className={['ImageContainer', !player.image_path ? 'Placeholder' : null].filter(Boolean).join(' ')}
			>
				<img
					alt={player.full_name}
					src={
						player.image_path
							? `https://images.overtime.tv/${player.image_path}?width=440&quality=40`
							: imgOteLogoWhiteSvg
					}
				/>
			</div>
			<div className="TextContainer">
				<div className="NameNumber">
					<div className="FirstName">{player.first_name}</div>
					<div className="Number">{player.jersey_number}</div>
				</div>
				<div className="LastName" style={{ color: foregroundColor }}>
					{player.family_name}
				</div>
				<div className="PositionContainer">
					<div className="Position">{positions[player.position]}</div>
					<FontAwesomeIcon icon={faArrowRight} color={foregroundColor} size={'xs'} />
				</div>
			</div>
		</a>
	);
};

export default Player;

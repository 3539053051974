import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { getEstDateTime } from '../lib';

export default ({ timestamp, format }: { timestamp: string; format?: string }) => {
	const [dateTime, setDateTime] = useState(getEstDateTime(timestamp, true));
	useEffect(() => {
		setDateTime(DateTime.fromISO(timestamp));
	}, [timestamp]);
	return <time dateTime={timestamp}>{format ? dateTime.toFormat(format) : dateTime.toLocaleString()}</time>;
};

import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, TouchEventHandler, createContext, useEffect, useRef, useState } from 'react';
import './MobileMenu.scss';

type MobileMenuContextValue = { isVisible: boolean };
const defaultContext: MobileMenuContextValue = { isVisible: false };
export const MobileMenuContext = createContext(defaultContext);

const MobileMenu = ({ children }: { children: ReactNode }) => {
	const [isVisible, setIsVisible] = useState(defaultContext.isVisible);
	const [isMenuVisible, setIsMenuVisible] = useState(defaultContext.isVisible);

	useEffect(() => {
		if (document.body.clientWidth < 1024) {
			document.body.style.overflow = isMenuVisible ? 'hidden' : '';
		}
		//Stop hiding overflow at start of open transition
		if (isVisible) {
			setIsMenuVisible(true);
		}
	}, [isVisible, isMenuVisible]);

	// We need to hide the overflow when the menu isn't visible because iOS will show it on overscroll
	const menuRef = useRef<HTMLDivElement>();
	useEffect(() => {
		if (!menuRef.current) {
			return;
		}
		menuRef.current.ontransitionend = () => {
			setIsMenuVisible(isVisible);
		};

		return () => {
			if (!menuRef.current) {
				return;
			}
			menuRef.current.ontransitionend = null;
		};
	}, [isVisible, setIsMenuVisible]);

	const lastTouchRef = useRef<React.TouchEvent<HTMLDivElement>>(null);
	const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
		if (menuRef.current.scrollHeight > menuRef.current.clientHeight) {
			//Don't try to detect swipes on the small number of phones that have to scroll the menu
			return;
		}
		if (!isVisible) {
			return;
		}
		const lastTouch = lastTouchRef.current;
		lastTouchRef.current = e;
		if (!lastTouch) {
			return;
		}
		const velocity = (e.touches[0].clientY - lastTouch.touches[0].clientY) / (e.timeStamp - lastTouch.timeStamp);
		//Empirically determined magic number
		if (velocity < -2) {
			setIsVisible(false);
		}
	};

	return (
		<MobileMenuContext.Provider value={{ isVisible }}>
			<FontAwesomeIcon
				className={`MenuIcon`}
				icon={isVisible ? faXmark : faBars}
				size="1x"
				role="button"
				aria-label="Main menu"
				aria-hidden={true}
				aria-expanded={isVisible}
				onClick={(_e) => setIsVisible(!isVisible)}
			/>
			<div className="MobileMenu" style={{ overflow: isMenuVisible ? '' : 'hidden' }}>
				<div ref={menuRef} className="Menu" role="navigation" aria-hidden={!isVisible} onTouchMove={onTouchMove}>
					{children}
				</div>
			</div>
		</MobileMenuContext.Provider>
	);
};

export default MobileMenu;

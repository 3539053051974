import React from 'react';
import { imgOteLogoWhiteSvg } from '~/src/images';
import { Urls } from '~/src/lib/enums/urls';
import { OteSeasonsOtePlayer } from '~/src/models/OteSeasonOtePlayer';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import { colorFromInt, KeyOfType } from '~/src/overtime-lib/src/www/lib';
import './SeasonLeaders.scss';

export type StatisticType = KeyOfType<OteSeasonsOtePlayer, number>;

const Player = ({ player, statistic }: { player: OteSeasonsOtePlayer; statistic: StatisticType }) => {
	// If player does not belong to a team, then remove their player profile link
	// The player profile has dependencies on the team and the quick solution was to disable links
	const playerProfileUrl = player.ote_player.ote_team ? `/players/${player.ote_player_id}` : null;

	return (
		<a className="Player inherit" href={playerProfileUrl} title={player.ote_player.full_name}>
			<div
				className="ImageContainer"
				style={{
					backgroundColor: player.ote_player.ote_team?.primary_color
						? colorFromInt(player.ote_player.ote_team?.primary_color)
						: 'black',
				}}
			>
				{player.ote_player.image_path ? (
					<img
						alt={player.ote_player.full_name}
						src={`https://images.overtime.tv/${player.ote_player.image_path}?format=webp&width=120`}
					/>
				) : (
					<img alt={player.ote_player.full_name} className="Placeholder" src={imgOteLogoWhiteSvg} />
				)}
			</div>
			<div className="NameTeam">
				<div className="Name">{player.ote_player.full_name}</div>
				<div className="Team">{player.ote_player.ote_team?.name}</div>
			</div>
			<div className="Statistic">
				<div className="Value">{player[statistic].toFixed(1)}</div>
				<label>{statistic.split('_').map((s) => s[0].toUpperCase())}</label>
			</div>
		</a>
	);
};

const SeasonLeaders = ({
	statistic,
	season = 'current',
	showSeason = false,
}: {
	statistic: StatisticType;
	season?: string;
	showSeason?: boolean;
}) => {
	const fetchResult = useFetch<OteSeasonsOtePlayer[]>({
		url: `${Urls.ApiRestOteSeasonsUrl}/${season}/leaders/${statistic}?limit=5`,
		key: 'ote_seasons_ote_players',
	});

	const seasonPlayers = fetchResult.result.read().filter((p) => p[statistic] > 0);

	return (
		<div className="SeasonLeaders">
			{showSeason ? <div className="Season">{seasonPlayers[0]?.ote_season.name}</div> : null}
			<div className="PlayersContainer">
				{seasonPlayers.length < 1 ? (
					<div className="NoLeaders">No current leaders.</div>
				) : (
					seasonPlayers.map((p) => <Player key={p.id} player={p} statistic={statistic} />)
				)}
			</div>
		</div>
	);
};

export default SeasonLeaders;
